.App {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px 0px 10px;
}

.checkBox_main {
  display: flex;
  /* margin-right: 20px; */
  width: 200px;
  align-items: center;
  /* justify-content: center; */
}

.label {
  cursor: default;
  margin: 0;
  padding: 5px;
}

.checkBox {
  height: 17px;
  width: 17px;
}

.iframe_container {
  width: 100%;
  height: 200px;
  border: 0;
  border-top: 1px solid darkgray;
}

.header_main{
  background: #fff;
  width: 50%;
  /* height: 250px; */
  /* overflow: auto; */
  border: 1px solid darkgray;
}

.iframe_label {
  font-weight: 700;
  padding: 10px 20px;
  margin: 0;
}

.iframe_main {
  display: flex;
  flex-wrap: wrap;
}

*{
  box-sizing: border-box;
}